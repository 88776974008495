import { getOnlineVoucherEventDetails, deleteOnlineVoucherEventDetails } from '@/services/coupon'
import viewFrame from '@/components/viewFrame'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import loading from '@/utils/loading.json'
import Utils from '@/utils/Utils.js'
import { mixin } from '@/mixins/viewDiolog'
export default {
  name: 'onlineVoucherEdit',
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      urlParams: null,
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      Object.assign(this.titleObject, { title: `${_this.$t('menu.onlineVoucherCode')} `, data: _this.urlParams.id })
      _this.setGlobalLoading(true)
	  const res = await getOnlineVoucherEventDetails(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.$message({
		    type: 'success',
		    message: _this.$t('message.loadSuccess')
		  })
		  this.$set(this, 'custName', res.data.online_voucher_code_event)
		  console.log(_this.custName)
	  }
	   _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'OnlineVoucherCodeEvent', item_id: _this.urlParams.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    handleBasicInfoForwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'offlineCouponDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'shop_item_stock') {
        this.$router.push({
          name: 'offlineCouponDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'onlineVoucherCodeAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'onlineVoucherCodeDetail',
          params: {
            id: this.urlParams.id
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'onlineVoucherCodeAdd'
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'onlineVoucherCodeEdit',
          params: {
            id: this.urlParams.id
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(this.urlParams.id, deleteOnlineVoucherEventDetails, 'onlineVoucherCodeList')
      }
      if (type?.action === 'edit_shop_items') {
        this.editCouponVoucherProduct(this.urlParams.id)
      }
    },
    editCouponVoucherProduct (couponId) {
      this.$router.push({
        name: 'onlineVoucherCodeProductList',
        query: {
          id: couponId
        }
      })
    },
    handleCusClick (item) {
      Utils.custOperate(item, this)
    }
  }
}
