export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'online',
    label: '',
    back: true,
    Object: 'value'
  }, {
    prop: 'new_user_only',
    label: '',
    back: true,
    Object: 'value'
  }, {
    prop: 'event_name',
    label: '',
    Object: 'value'
  }, {
    prop: 'event_date',
    label: '',
    Object: 'value'
  }, {
    prop: 'maximum_use_count',
    label: '',
    Object: 'value'
  }, {
    prop: 'same_user_redemption_limit',
    label: '',
    Object: 'value'
  }, {
    prop: 'voucher_type',
    label: '',
    Object: 'value'
  }, {
    prop: 'applied_target_type',
    label: '',
    Object: 'value'
  }, {
    prop: 'code_generation',
    label: '',
    Object: 'value'
  }]
}
